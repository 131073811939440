export interface AdSettings {
  whitelist: string[];
}

export function shouldShowAds(
  slug: string,
  settings: AdSettings | undefined
): boolean {
  if (!settings?.whitelist) {
    return true;
  }
  return !settings.whitelist.includes(slug);
}
