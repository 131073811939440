'use client';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { AdSettings } from 'config/adSettings';
import AdWhitelistQuery from 'models/AdWhitelist/AdWhitelistQuery';
import AdWhitelistType from 'models/AdWhitelist/AdWhitelistType';
import fetcher from 'utils/fetcher';
import useFavorites, { Favorite } from 'hooks/useFavorites';
import { useSession } from 'next-auth/react';

export type MenuName = 'Home' | 'Listen' | 'Read' | undefined;

interface SiteState {
  activeNavMenu: MenuName;
  setActiveNavMenu: (menu: MenuName) => void;
  adSettings: AdSettings | undefined;
  favoriteSongs?: Map<string, Favorite>;
  fetchFavoriteSongs: () => Promise<Map<string, Favorite> | undefined>;
}

type Props = {
  children: React.ReactNode;
};

const initialSiteState: SiteState = {
  activeNavMenu: undefined,
  setActiveNavMenu: (menu) => {
    return menu;
  },
  adSettings: undefined,
  favoriteSongs: undefined,
  fetchFavoriteSongs: async () => {
    return undefined;
  }
};

export const SiteContext = React.createContext<SiteState>(initialSiteState);

export const SiteContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [activeNavMenu, setActiveNavMenu] = useState(
    initialSiteState.activeNavMenu
  );

  const [adSettings, setAdSettings] = useState<AdSettings | undefined>(
    undefined
  );
  const [favoriteSongs, fetchFavoriteSongs] = useFavorites('song');
  const sessionData = useSession();
  const status = sessionData?.status;

  async function updateAdSettings() {
    try {
      const data = await fetcher<AdWhitelistType>(AdWhitelistQuery, {
        slug: 'the-current/ad-whitelist'
      });

      if (data.adWhitelist.json) {
        setAdSettings(JSON.parse(data.adWhitelist.json));
      }
    } catch (e) {
      console.error(`Error trying to update ad settings: ${e}`);
    }
  }

  useEffect(() => {
    if (!adSettings) {
      updateAdSettings();
    }
  }, []);

  useEffect(() => {
    if (status === 'authenticated') {
      fetchFavoriteSongs();
    }
  }, [status]);

  return (
    <SiteContext.Provider
      value={{
        ...initialSiteState,
        activeNavMenu,
        setActiveNavMenu,
        adSettings,
        favoriteSongs,
        fetchFavoriteSongs
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
