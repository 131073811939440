'use client';

import React, { FC, useContext } from 'react';
import { shouldShowAds } from 'config/adSettings';
import { SiteContext } from 'context/SiteContext';
import { usePathname } from 'next/navigation';

function withAdSettings<T>(
  AdComponent: React.ComponentType<T>
): React.ComponentType<T> {
  const Advertisement: FC<T> = (props) => {
    const pathname = usePathname();

    const context = useContext(SiteContext);

    return shouldShowAds(pathname ?? '', context.adSettings) ? (
      <AdComponent key="" {...props} />
    ) : null;
  };

  return Advertisement;
}

export default withAdSettings;
