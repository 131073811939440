import { FC } from 'react';

interface Props {
  color?: string;
  height?: string;
  width?: string;
}

const IconCloseModal: FC<Props> = ({
  color = 'var(--color-white)',
  height = '14',
  width = '14'
}) => {
  return (
    <svg
      aria-label="Close"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCloseModal;
