import { GraphQLClient } from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';

// Only env vars that begin with NEXT_PUBLIC_ should be visible to browsers
// see: https://nextjs.org/docs/basic-features/environment-variables
const ENDPOINT = 'https://cmsapi.publicradio.org/graphql';

async function fetcher<T = unknown>(
  query: RequestDocument,
  vars?: Record<string, unknown>
): Promise<T> {
  const graphQLClient = new GraphQLClient(ENDPOINT, {
    method: `POST`,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await graphQLClient.request<T>(query, vars);
}

export default fetcher;
