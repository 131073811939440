import React, { FC } from 'react';
import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';
import PrimaryCollection from 'models/PrimaryCollection/PrimaryCollectionType';
import sty from './PostTitle.module.css';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';

interface LayoutPostTitle {
  title: string;
  subTitle?: string | null;
  topic?: PrimaryCollection | null;
  className?: string;
  underwriting?: boolean;
  useAutoTypeSnug?: boolean;
  cmsId?: string;
  primaryCollectionSlug?: string | null;
}

const LayoutPostArticle: FC<LayoutPostTitle> = ({
  title,
  subTitle,
  topic,
  className,
  underwriting,
  useAutoTypeSnug = true,
  cmsId,
  primaryCollectionSlug
}) => {
  const data = {
    title: title || '',
    subTitle: subTitle || '',

    get hasSubtitle() {
      return !!this.subTitle;
    }
  };

  const autoTypeSnug = `type-snug${topic?.title ? `-none` : `-down`}`;

  return (
    <>
      {topic?.title && (
        <LinkNoPrefetch
          className={`category-link ${sty.categoryLink}`}
          href={`/${topic.canonicalSlug}`}
        >
          {topic?.title}
        </LinkNoPrefetch>
      )}
      <AdvertisementBanner
        id="div-gpt-ad-8382356-3"
        adUnitPath={`/22546217694/apmg/mpr/the-current/${primaryCollectionSlug}`}
        cmsId={cmsId || ''}
        lazyload={false}
        sizes={[[320, 25]]}
        nomargin={true}
      />
      <h2
        className={`${className ? className : ''} ${
          useAutoTypeSnug ? autoTypeSnug : ''
        } ${underwriting ? sty.headingWithUnderwriting : ''}`}
      >
        {data.title} {data.title && data.hasSubtitle && <>-</>}
      </h2>
      {data.hasSubtitle && <h2 className="type-snug-up">{data.subTitle}</h2>}
    </>
  );
};

export default LayoutPostArticle;
