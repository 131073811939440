import React, { FC } from 'react';
import PrimaryVisuals from 'models/PrimaryVisuals/PrimaryVisualType';
import { Image } from '@apmg/mimas';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';

interface Props {
  primaryVisuals: PrimaryVisuals;
  addFavorite?: React.ReactNode;
  showCaption?: boolean;
}

const PostFeatureImage: FC<Props> = ({
  primaryVisuals,
  addFavorite,
  showCaption = true
}) => {
  const isVideo = primaryVisuals.video ? true : false;
  const propData = isVideo ? primaryVisuals.video : primaryVisuals.lead;

  if (!propData) {
    return null;
  }

  const data = {
    imageAlt: propData.caption || '',
    creditCaption: propData.caption || '',
    creditName: propData.credit?.name || '',

    get creditCaptionSpacer(): string {
      return this.creditCaption ? 'image-caption-spacer' : '';
    }
  };

  return (
    <figure className="story-feature-image figure-full-width">
      {isVideo ? (
        <VideoPlayer video={propData} />
      ) : (
        <Image
          image={propData}
          alt={data.imageAlt}
          priority
          aspectRatio="square"
        />
      )}
      {showCaption && (
        <figcaption className="figure-caption">
          {data.creditCaption && (
            <span className="figure-caption-description type-caption">
              {data.creditCaption}
            </span>
          )}
          {data.creditName && (
            <span className="figure-caption-name type-micro">
              {data.creditName}
            </span>
          )}
        </figcaption>
      )}
      {addFavorite}
    </figure>
  );
};

export default PostFeatureImage;
