'use client';
import React, { FC } from 'react';
import useAds from 'hooks/useAds';
import styles from 'styles/modules/AdvertisementBanner.module.css';
import withAdSettings from './withAdSettings';

interface Props {
  id?: string;
  adUnitPath?: string;
  sizeMap?: Record<number, number[]>;
  sizes?: number[][];
  cmsId: string;
  primaryCollection?: string;
  collections?: string[];
  position?: string;
  lazyload?: boolean;
  nomargin?: boolean;
}
const defaultAdUnitPath = '/22546217694/apmg/mpr/the-current/';

const AdvertisementBanner: FC<Props> = ({
  id = 'story_ad',
  adUnitPath = defaultAdUnitPath,
  sizes = [
    [320, 50],
    [728, 90]
  ],
  cmsId,
  primaryCollection,
  collections = [],
  position = 'atf',
  lazyload = false,
  nomargin = false
}) => {
  let adSize = sizes[0];
  if (process.browser) {
    const screenWidth = window.innerWidth;
    sizes.forEach((size: number[]) => {
      if (screenWidth >= size[0]) {
        adSize = size;
      }
    });
  }
  useAds({
    adUnitPath,
    id,
    sizes: adSize,
    cmsId,
    primaryCollection,
    collections,
    position,
    lazyload
  });
  return (
    <>
      <div
        id={id}
        className={nomargin ? '' : `ad-banner ${styles.advertisement}`}
      />
    </>
  );
};

export default withAdSettings<Props>(AdvertisementBanner);
// export default AdvertisementBanner;
