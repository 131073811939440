import React, { FC } from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const LayoutBodyWrapper: FC<Props> = ({
  children,
  className = 'container'
}) => {
  return (
    <main id="main-content">
      <div className={className}>{children}</div>
    </main>
  );
};

export default LayoutBodyWrapper;
