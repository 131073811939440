'use client';
import { useEffect } from 'react';

// see https://developers.google.com/tag-manager/devguide

const useAds = ({
  adUnitPath,
  id,
  sizes,
  cmsId,
  primaryCollection,
  collections,
  position = 'atf',
  googletag = false, // for testing only
  lazyload = false
}) => {
  
  useEffect(() => {
    if (googletag || (process.browser && window.googletag)) {
      googletag = googletag || window.googletag;
      let slotId;

      googletag?.cmd?.push(function () {
        slotId = googletag
          .defineSlot(adUnitPath, sizes, id)
          // .defineSizeMapping(builtMapping)
          .addService(googletag.pubads())
          .setTargeting('contentId', cmsId)

        if (lazyload)
          googletag
            .pubads()
            .enableLazyLoad({ fetchMarginPercent: 5, renderMarginPercent: 5 });

        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });

      googletag?.cmd?.push(function () {
        googletag.display(id);
      });

      return () => {
        googletag?.cmd?.push(function () {
          googletag.destroySlots([slotId]);
        });
      };
    }
  }, []);
};

export default useAds;
